/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body {
  --ion-padding: 4px;
  --ion-margin: 8px;
}

.data-table-ctr {
  padding: 0 !important;
  font-size: 16px;
}

.data-table-ctr {
  ion-grid {
    padding: 0px !important;

    &.header-grid {
      flex: 0 0 0 !important;
    }
  }
  ion-col {
    font-size: 14px;
    padding: 8px !important;
    margin: 0px !important;
    vertical-align: middle;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ion-grid {
    &.header-grid {
      ion-col {
        padding: 8px 4px !important;
      }
    }
  }
}

ion-input {
  margin: 0 !important;
  input {
    padding: 8px !important;
  }
}

.label-stacked.sc-ion-label-md-h {
  margin-bottom: 8px !important;
}
