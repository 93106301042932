ion-label,
ion-text {
  &.form-label {
    color: var(--ion-color-dark-tint);
    font-size: 0.8rem;
    width: 100% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.required {
      &:after {
        margin-left: 2px;
        font-weight: bold;
        font-size: large;
        color: var(--ion-color-danger);
        content: "*";
        line-height: 1rem;
      }
    }
  }
}

ion-input {
  --height: var(--btn-height) !important;
  --background: var(--ion-color-light) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
  --padding-end: 16px !important;

  &.no-padding {
    --padding-start: 0px !important;
    --padding-end: 0px !important;
  }
}

ion-searchbar {
  --background: var(--ion-color-light) !important;
  --color: var(--ion-color-primary) !important;
  --border-radius: var(--border-radius-size) !important;
  --icon-color: var(--ion-color-medium-shade) !important;
  --clear-button-color: var(--ion-color-medium-shade) !important;
  --box-shadow: 0 !important;
}

ion-textarea {
  --background: var(--ion-color-light) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-select {
  height: var(--btn-height) !important;
  --background: var(--ion-color-light) !important;
  --padding-start: 24px !important;
  color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
  --padding-end: 16px !important;
}

ion-item {
  --background: 0;

  &.picker-layout {
    margin-top: auto;
    margin-bottom: auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid var(--ion-color-dark);
    background: var(--ion-color-light);
  }
}

ion-grid.form-grid {
  ion-row {
    padding-bottom: 24px;
  }
}
