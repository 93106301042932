// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-padding: 12px;
  --ion-background-color: #fff;
  // --ion-margin: 8px;

  /** primary **/
  --ion-color-primary: #3aaa35;
  --ion-color-primary-rgb: 58, 170, 53;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #33962f;
  --ion-color-primary-tint: #4eb349;

  /** secondary **/
  --ion-color-secondary: #555;
  --ion-color-secondary-rgb: 85, 85, 85;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4b4b4b;
  --ion-color-secondary-tint: #666666;

  /** tertiary **/
  --ion-color-tertiary: #097200;
  --ion-color-tertiary-rgb: 9, 114, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #086400;
  --ion-color-tertiary-tint: #22801a;

  /** success **/
  --ion-color-success: #097200;
  --ion-color-success-rgb: 9, 114, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #086400;
  --ion-color-success-tint: #22801a;

  /** warning **/
  --ion-color-warning: #ffd789;
  --ion-color-warning-rgb: 255, 215, 137;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bd79;
  --ion-color-warning-tint: #ffdb95;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #dedede;
  --ion-color-light-rgb: 222, 222, 222;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cccccc;
  --ion-color-light-tint: #e1e1e1;

  --ion-color-female: #df87e0;
  --ion-color-female-rgb: 223, 135, 224;
  --ion-color-female-contrast: #000000;
  --ion-color-female-contrast-rgb: 0, 0, 0;
  --ion-color-female-shade: #c477c5;
  --ion-color-female-tint: #e293e3;

  --ion-color-male: #5260ff;
  --ion-color-male-rgb: 82, 96, 255;
  --ion-color-male-contrast: #ffffff;
  --ion-color-male-contrast-rgb: 255, 255, 255;
  --ion-color-male-shade: #4854e0;
  --ion-color-male-tint: #6370ff;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-lightest: #000;

    --ion-color-primary: #3aaa35;
    --ion-color-primary-rgb: 58, 170, 53;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #33962f;
    --ion-color-primary-tint: #4eb349;

    --ion-color-secondary: #097200;
    --ion-color-secondary-rgb: 9, 114, 0;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #086400;
    --ion-color-secondary-tint: #22801a;

    --ion-color-tertiary: #097200;
    --ion-color-tertiary-rgb: 9, 114, 0;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #086400;
    --ion-color-tertiary-tint: #22801a;

    --ion-color-success: #38ff88;
    --ion-color-success-rgb: 56, 255, 136;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #31e078;
    --ion-color-success-tint: #4cff94;

    --ion-color-warning: #ffd789;
    --ion-color-warning-rgb: 255, 215, 137;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bd79;
    --ion-color-warning-tint: #ffdb95;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f2f2f2;
    --ion-color-dark-rgb: 242, 242, 242;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d5d5d5;
    --ion-color-dark-tint: #f3f3f3;

    --ion-color-medium: #4a484c;
    --ion-color-medium-rgb: 74, 72, 76;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #413f43;
    --ion-color-medium-tint: #5c5a5e;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

.ion-color-female {
  --ion-color-base: var(--ion-color-female);
  --ion-color-base-rgb: var(--ion-color-female-rgb);
  --ion-color-contrast: var(--ion-color-female-contrast);
  --ion-color-contrast-rgb: var(--ion-color-female-contrast-rgb);
  --ion-color-shade: var(--ion-color-female-shade);
  --ion-color-tint: var(--ion-color-female-tint);
}

.ion-color-male {
  --ion-color-base: var(--ion-color-male);
  --ion-color-base-rgb: var(--ion-color-male-rgb);
  --ion-color-contrast: var(--ion-color-male-contrast);
  --ion-color-contrast-rgb: var(--ion-color-male-contrast-rgb);
  --ion-color-shade: var(--ion-color-male-shade);
  --ion-color-tint: var(--ion-color-male-tint);
}
